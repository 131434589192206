<template>
  <div class="p-2">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: 100,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'ad_soyad'">
          <router-link
            :to="{
              name: 'uye-guncelle',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="d-block text-dark font-weight-bold">{{ props.row.ad }} {{ props.row.soyad }}</span>
            <span class="d-block">{{ props.row.e_mail }}</span>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'uye_grubu'">
          <span class="rounded-0 bg-info text-light p-1 d-block text-center">
            {{ props.row.uye_grup }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'telefon'">
          {{ props.row.telefon }}
        </span>
        <span v-else-if="props.column.field === 'tarih'">
          {{ props.row.save_date | moment }}
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.statu"
            switch
            @change="handlerStatu(props.row.k_no, $event)"
            size="lg"
          />
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              class="rounded-0"
              variant="warning"
              :to="{
                name: 'uye-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" />
            </b-button>
            <b-button variant="danger" class="rounded-0" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center my-3">
            <v-select
              v-model="filter.pageSize"
              :options="perPageOptions"
              :clearable="false"
              style="width: 200px"
              class="invoice-filter-select"
              @input="(value) => handlerPageSizeChange(value)"
            />
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="count"
              :per-page="filter.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="my-3"
              @input="(value) => handlerPageChange(value)"
            >
              <template #prev-text>
                <i class="fad fa-chevron-left" />
              </template>
              <template #next-text>
                <i class="fad fa-chevron-right" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import BTableCard from '@/components/cards/BTableCard.vue';
import { defineComponent, ref, computed } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    VueGoodTable,
    BTableCard,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.perPageOptions = ref([20, 40, 50, 80, 100]);
    expo.count = ref(0);
    expo.filter = ref({
      page: 0,
      pageSize: 20,
    });
    expo.columns = ref([
      {
        label: 'İsim ( Ünvan )',
        field: 'ad_soyad',
      },
      {
        label: 'İskonto Grubu',
        field: 'uye_grubu',
        width: '15%',
      },
      {
        label: 'Telefon',
        field: 'telefon',
        width: '10%',
        thClass: 'text-right',
        thClass: 'text-right',
      },
      {
        label: 'Üyelik Tarihi',
        field: 'tarih',
        width: '12%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '5%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      await store.dispatch('uyeListele', expo.filter.value).then((res) => {
        if (res.data.success) {
          expo.rows.value = store.getters.getUyeler;
          expo.count.value = res.data.count;
          context.emit('total', expo.count.value);
        }
      });
      context.emit('show', false);
    };
    handlerFetchAllData();

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      handlerFetchAllData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.filter.value.page = expo.filter.value.page - 1;
      handlerFetchAllData();
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('uyeSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerStatu = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('/uyeler/uye-statu-guncelle', { k_no, statu: event }).then((res) => {
          if (res.data.success === true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          }
        });
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
