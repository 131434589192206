<template>
  <div class="p-2">
    <b-row>
      <b-col cols="12" md="3" v-for="(item, index) in urunler" :key="index">
        <b-card class="product-card rounded-0" no-body>
          <div class="item-img text-center">
            <b-link :to="{ name: 'urun-guncelle', params: { k_no: item.k_no } }">
              <b-img fluid :src="imageFile(item.gorseller)" />
            </b-link>
            <div class="item-price">
              <span class="text-primary">{{ item.satis_fiyat | cur }} {{ item.kur_turu }}</span>
            </div>
          </div>
          <b-card-body class="p-0 p-3">
            <div class="item-wrapper">
              <div class="item-rating d-flex justify-content-between">
                <ul class="unstyled-list list-inline d-flex">
                  <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-1': star - 1 }">
                    <i class="fal fa-star" />
                  </li>
                </ul>
                <span class="text-primary font-weight-bold">{{ item.kategori[defaultDil].baslik }}</span>
              </div>
              <b-media no-body class="mt-0">
                <!-- <b-media-aside v-for="(lang, i) in urun.icerik" :key="i" class="m-0 mr-25">
                <b-avatar rounded class="rounded-0" variant="light-success" size="22"> {{ i }} </b-avatar>
              </b-media-aside> -->
              </b-media>
            </div>
            <h6 class="item-name">
              <b-link class="text-body" :to="{ name: 'urun-guncelle', params: { k_no: item.k_no } }">
                {{ item.icerik[defaultDil].baslik | str_limit(24) }}
              </b-link>
            </h6>
          </b-card-body>
          <div class="item-options text-center">
            <b-button
              :variant="item.statu == 'yayinda' ? 'success' : item.statu == 'yayin_disi' ? 'danger' : 'info'"
              squared
              tag="a"
              class="btn-cart"
              :to="{ name: 'urun-guncelle', params: { k_no: item.k_no } }"
            >
              <i class="fad fa-list" />
              {{ item.statu == 'yayinda' ? 'Yayında' : item.statu == 'yayin_disi' ? 'Yayın Dışı' : 'Taslak' }}
            </b-button>
            <b-button
              variant="warning"
              squared
              tag="a"
              class="btn-cart"
              :to="{ name: 'urun-guncelle', params: { k_no: item.k_no } }"
            >
              <i class="fad fa-pencil" />
              GÜNCELLE
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import { computed, defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  props: {
    urunler: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const fileName = store.getters.getUserFirma.db;

    expo.imageFile = computed(() => {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);

      return (img) => {
        if (img.length > 0) {
          const selectImg = img.find((x) => x.kapak == true);
          if (selectImg != undefined) {
            return `${base_url}uploads/${fileName}/urun/${selectImg.gorsel}`;
          } else {
            return `${base_url}uploads/${fileName}/urun/${img[0].gorsel}`;
          }
        } else {
          return `${base_url}uploads/no/no-image.jpg`;
        }
      };
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.product-card {
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    transform: translateY(-5px);
    box-shadow: 0 4px 15px 0 rgba(#000, 0.25);
  }

  .item-img {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 250px;
    overflow: hidden;
    .img-fluid {
      width: 100%;
      max-height: 300px !important;
    }

    .item-price {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10px 10px;
      text-align: start;
      background: linear-gradient(270deg, rgba(244, 244, 244, 0.1) 20%, rgba(222, 222, 222, 1) 100%);
      span {
        font-weight: 600;
        font-size: 1.2rem;
        // color: rgb(62, 62, 62);
      }
    }
  }

  // ratings
  .item-rating {
    ul {
      list-style: none;
      margin-bottom: 0;
    }
    i {
      height: 1.143rem;
      width: 1.143rem;
      font-size: 1rem;
    }
  }

  // Item name
  .item-name {
    padding-top: 10px;
    margin-bottom: 0;
    a {
      font-size: 1.1rem;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .item-description {
    font-size: 0.875rem;
  }

  .item-options {
    display: flex;
    flex-wrap: wrap;

    .btn-cart,
    .btn-wishlist {
      flex-grow: 1;
      border-radius: 0;
    }
  }
}
</style>
