<template>
  <div class="product-search">
    <b-form @submit.stop.prevent="$emit('handlerFilter', true)">
      <b-row>
        <b-col cols="12" md="4">
          <b-form-input size="lg" v-model="filter.arama" class="rounded-0 mr-1" placeholder="Arama Kriteri..." />
        </b-col>
        <b-col cols="12" md="4" v-if="kategoriler.length > 0">
          <treeselect
            :options="kategoriler"
            v-model="filter.kategori_k_no"
            :normalizer="normalizer"
            :flat="true"
            :sort-value-by="sortValueBy"
            :default-expand-level="1"
            noChildrenText="Alt Öge Yok."
            placeholder="Lütfen Kategori Seçiniz"
            aria-describedby="input-1-live-feedback"
            class="rounded-0"
            :multiple="true"
            :max-height="200"
          />
        </b-col>
        <b-col cols="12" md="4" v-if="bolgeler.length > 0">
          <treeselect
            :options="bolgeler"
            v-model="filter.bolge_k_no"
            :normalizer="normalizer"
            :flat="true"
            :sort-value-by="sortValueBy"
            :default-expand-level="1"
            noChildrenText="Alt Öge Yok."
            placeholder="Lütfen Lokasyon Seçiniz"
            aria-describedby="input-1-live-feedback"
            class="rounded-0"
            :multiple="true"
            :max-height="200"
          />
        </b-col>
        <b-col cols="12" md="4">
          <b-row class="p-0 m-0">
            <b-col class="p-0">
              <v-select
                v-model="filter.siralama"
                :options="siralamalar"
                :reduce="(durum) => durum.id"
                label="title"
                :clearable="false"
                placeholder="Siralama..."
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-col>
            <b-col class="p-0">
              <v-select
                v-model="filter.urun_tipi"
                :options="urunTipleri"
                :reduce="(durum) => durum.value"
                label="title"
                :clearable="false"
                placeholder="Ürün Tipi..."
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <b-row class="p-0 m-0">
            <b-col class="p-0">
              <v-select
                v-model="filter.statu"
                :options="statuler"
                :reduce="(durum) => durum.id"
                label="title"
                :clearable="true"
                placeholder="Durum..."
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-col>
            <b-col class="p-0">
              <v-select
                v-model="filter.satis_durum"
                :options="satisDurum"
                :reduce="(durum) => durum.id"
                label="title"
                :clearable="true"
                placeholder="Satış..."
                class="invoice-filter-select d-block select-size-lg"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4">
          <treeselect
            :options="vitrin"
            v-model="filter.vitrin_gosterim"
            :normalizer="normalizerVitrin"
            :flat="true"
            :sort-value-by="sortValueBy"
            :default-expand-level="1"
            noChildrenText="Alt Öge Yok."
            placeholder="Lütfen Lokasyon Seçiniz"
            aria-describedby="input-1-live-feedback"
            class="rounded-0"
            :multiple="true"
            :max-height="200"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-button type="submit" block variant="info" size="lg" class="rounded-0">
            <i class="fad fa-filter"></i> FİLTRE UYGULA
          </b-button>
        </b-col>
        <b-col cols="12" md="6">
          <b-button block variant="danger" size="lg" class="rounded-0" @click="$emit('filtered', false)">
            <i class="fad fa-window-close"></i> TEMİZLE
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { defineComponent, computed, ref } from '@vue/composition-api';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default defineComponent({
  components: { vSelect, Treeselect },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kategoriler = computed(() => store.getters.getKategoriler);
    expo.bolgeler = computed(() => store.getters.getBolgeler);

    expo.sortValueBy = ref('INDEX');

    expo.normalizer = (node) => {
      return {
        id: node.k_no && node.k_no,
        label: node.icerik != undefined && node.icerik[expo.defaultDil.value].baslik,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    expo.normalizerVitrin = (node) => {
      return {
        id: node.id,
        label: node.title,
      };
    };

    expo.siralamalar = ref([
      { id: 'urun_kodu_az', title: 'Ürün Kodu ( A-Z )' },
      { id: 'urun_kodu_za', title: 'Ürün Kodu ( Z-A )' },
      { id: 'urun_adi_az', title: 'Ürün Adı ( A-Z )' },
      { id: 'urun_adi_za', title: 'Ürün Adı ( Z-A )' },
      { id: 'fiyat_az', title: 'Fiyata Göre ( A-Z )' },
      { id: 'fiyat_za', title: 'Fiyata Göre ( Z-A )' },
      { id: 'stok_az', title: 'Stok Miktarına Göre ( A-Z )' },
      { id: 'stok_za', title: 'Stok Miktarına Göre ( Z-A )' },
      { id: 'once_eklenen', title: 'Önce Eklenen' },
      { id: 'sonra_eklenen', title: 'Sonra Eklenen' },
    ]);

    expo.urunTipleri = ref([
      { value: 'villa', title: 'Villa' },
      { value: 'yat', title: 'Yat' },
      { value: 'tur', title: 'Tur' },
      { value: 'arac_kira', title: 'Araç' },
      { value: 'emlak', title: 'Emlak' },
    ]);

    expo.statuler = ref([
      { id: 'yayinda', title: 'Yayında' },
      { id: 'yayin_disi', title: 'Yayın Dışı' },
      { id: 'taslak', title: 'Taslak' },
    ]);

    expo.satisDurum = ref([
      { id: true, title: 'Satışa Açık' },
      { id: false, title: 'Satışa Kapalı' },
    ]);

    expo.vitrin = ref([
      { id: 'vitrin_1', title: 'Vitrin 1' },
      { id: 'vitrin_2', title: 'Vitrin 2' },
      { id: 'yeni_urun', title: 'Yeni Ürün' },
      { id: 'firsat_urun', title: 'Fırsat Ürünü' },
      { id: 'cok_satan', title: 'Çok Satan' },
      { id: 'begenilen', title: 'Beğenilen' },
      { id: 'indirimde', title: 'İndirimde' },
      { id: 'ozel_urun', title: 'Özel Ürün' },
    ]);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
