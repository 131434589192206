<template>
  <tr>
    <td>
      <i
        class="fad fa-sort fa-2x draggable-task-handle"
        style="cursor: move"
      ></i>
    </td>
    <td>{{ index + 1 }}</td>
    <td class="font-weight-bold">
      <div class="w-100 d-flex justify-content-between">
        {{ bolgeBaslik(item.k_no) }}
        <div>
          <b-badge
            class="rounded-0 mr-1"
            variant="warning"
            v-b-tooltip.hover.top="'Transfer Bölge Alış Saati'"
          >
            <i class="fad fa-clock"></i>
            {{ bolgeTarifeSure(item.k_no).bolge_alis }}
          </b-badge>
          <b-badge
            class="rounded-0"
            variant="info"
            v-b-tooltip.hover.top="'Transfer Ortalama Süresi'"
          >
            <i class="fad fa-clock"></i> {{ bolgeTarifeSure(item.k_no).sure }}
          </b-badge>
        </div>
      </div>
    </td>
    <td width="10%" v-for="(arac, i) in item.araclar" :key="`ac-${i}`">
      <b-input-group>
        <b-form-input
          :disabled="!item.statu"
          v-model="arac.fiyat"
          size="sm"
          class="rounded-0 text-right"
        />
        <b-input-group-append is-text>
          <small style="font-size: 10px">{{ kurTurleri.varsayilan }}</small>
        </b-input-group-append>
      </b-input-group>
    </td>
    <td width="5%" class="text-right">
      <b-button-group>
        <b-button
          squared
          size="sm"
          :variant="item.statu ? 'info' : 'danger'"
          v-b-tooltip.hover.left="
            item.statu ? 'Bölge Pasif Et' : 'Bölge Aktif Et'
          "
          @click="item.statu = !item.statu"
        >
          <i :class="`fad fa-${item.statu ? 'eye' : 'eye-slash'}`" />
        </b-button>
        <b-button
          squared
          size="sm"
          variant="danger"
          @click="$emit('removeItem', index)"
        >
          <i class="fad fa-trash"></i>
        </b-button>
      </b-button-group>
    </td>
  </tr>
</template>

<script>
import store from "@/store";
import { defineComponent, ref, computed, toRefs } from "@vue/composition-api";
export default defineComponent({
  props: {
    index: [Number],
    item: {
      type: Object,
      required: true,
    },
    tarife: {
      type: Object,
      required: true,
    },
  },
  setup(props, cntxt) {
    const expo = {};
    const { tarife } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    const tumBolgeler = computed(() =>
      store.getters.getTumBolgeler.filter((x) => x.statu == true)
    );

    expo.bolgeBaslik = computed(() => {
      return (k_no) => {
        const bolge = tumBolgeler.value.find((x) => x.k_no == k_no);
        if (bolge != undefined) {
          return bolge.icerik[expo.defaultDil.value].baslik;
        } else {
          return "";
        }
      };
    });

    expo.bolgeTarifeSure = computed(() => {
      return (k_no) => {
        const payload = tarife.value.aktif_bolgeler.find((x) => x.k_no == k_no);
        if (payload != undefined) {
          return payload;
        } else {
          return "";
        }
      };
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
