var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
      enabled: true,
      perPage: 100,
    },"styleClass":"vgt-table striped bordered"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'ad_soyad')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'uye-guncelle',
            params: { k_no: props.row.k_no },
          }}},[_c('span',{staticClass:"d-block text-dark font-weight-bold"},[_vm._v(_vm._s(props.row.ad)+" "+_vm._s(props.row.soyad))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(props.row.e_mail))])])],1):(props.column.field === 'uye_grubu')?_c('span',[_c('span',{staticClass:"rounded-0 bg-info text-light p-1 d-block text-center"},[_vm._v(" "+_vm._s(props.row.uye_grup)+" ")])]):(props.column.field === 'telefon')?_c('span',[_vm._v(" "+_vm._s(props.row.telefon)+" ")]):(props.column.field === 'tarih')?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(props.row.save_date))+" ")]):(props.column.field === 'statu')?_c('span',[_c('b-form-checkbox',{staticClass:"custom-control-info",attrs:{"switch":"","size":"lg"},on:{"change":function($event){return _vm.handlerStatu(props.row.k_no, $event)}},model:{value:(props.row.statu),callback:function ($$v) {_vm.$set(props.row, "statu", $$v)},expression:"props.row.statu"}})],1):(props.column.field === 'action')?_c('span',{staticClass:"float-right px-1"},[_c('b-button-group',{staticClass:"pb-25",attrs:{"size":"sm"}},[_c('b-button',{staticClass:"rounded-0",attrs:{"variant":"warning","to":{
              name: 'uye-guncelle',
              params: { k_no: props.row.k_no },
            }}},[_c('i',{staticClass:"fad fa-edit"})]),_c('b-button',{staticClass:"rounded-0",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handlerRemove(props.row.k_no)}}},[_c('i',{staticClass:"fad fa-trash"})])],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"pagination-bottom"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center my-3"},[_c('v-select',{staticClass:"invoice-filter-select",staticStyle:{"width":"200px"},attrs:{"options":_vm.perPageOptions,"clearable":false},on:{"input":function (value) { return _vm.handlerPageSizeChange(value); }},model:{value:(_vm.filter.pageSize),callback:function ($$v) {_vm.$set(_vm.filter, "pageSize", $$v)},expression:"filter.pageSize"}})],1),_c('div',[_c('b-pagination',{staticClass:"my-3",attrs:{"value":1,"total-rows":_vm.count,"per-page":_vm.filter.pageSize,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.handlerPageChange(value); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('i',{staticClass:"fad fa-chevron-left"})]},proxy:true},{key:"next-text",fn:function(){return [_c('i',{staticClass:"fad fa-chevron-right"})]},proxy:true}])})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }