<template>
  <BTableCard
    :searchActive="true"
    :newAddActive="false"
    :totalActive="false"
    :show="show"
    :icon="'fad fa-plane-alt'"
    :title="pageTitle"
    :total="total"
  >
    <template v-slot:headerAction>
      <div class="d-flex">
        <v-select
          v-model="tarife"
          :options="tarifeBolgeleri"
          :clearable="false"
          style="width: 400px"
          class="invoice-filter-select"
          @input="(value) => handlerTarifeBolgeChange(value)"
        >
          <template slot="selected-option" slot-scope="option">
            <i :class="`fad fa-${option.icon}`"></i>
            {{ option.icerik["tr"].baslik }}
          </template>
          <template slot="option" slot-scope="option">
            <i :class="`fad fa-${option.icon}`"></i>
            {{ option.icerik["tr"].baslik }}
          </template>
        </v-select>
        <b-button
          squared
          variant="success"
          class="icon btn-icon ml-1"
          @click="onSubmit"
          v-b-tooltip.hover="'Değişiklikleri Kaydet'"
        >
          <i class="fad fa-save" /> KAYDET
        </b-button>
      </div>
    </template>
    <template v-slot:body>
      <template v-if="Object.keys(form).length">
        <div class="w-100 p-2">
          <template v-if="!form.bolgeler.length">
            <b-alert variant="warning" show class="text-center">
              <h4 class="alert-heading">Bilgi!</h4>
              <p>
                Henüz Tarife oluşturulmamış. Tanımlamalar -> Transfer İşlemleri
                ->
                <router-link to="/tanimlama/transfer/tarife-bolgeleri">
                  Tarife Bölgelerin
                </router-link>
                den bölge oluşturabilirsiniz.
              </p>
            </b-alert>
          </template>
          <template v-else>
            <b-form @submit.stop.prevent="onSubmit">
              <table
                class="table table-bordered table-sm table-striped table-responsive"
                :class="tarife.statu ? 'border-success' : 'border-danger'"
              >
                <thead>
                  <tr>
                    <th scope="col" width="3%"><i class="fad fa-sort"></i></th>
                    <th style="vertical-align: middle" width="3%">#</th>
                    <th scope="col" width="20%" style="vertical-align: middle">
                      {{ tarife.icerik[defaultDil].baslik }}
                    </th>
                    <th
                      v-for="(arac, index) in araclar"
                      :key="index"
                      class="text-center"
                    >
                      {{ arac.icerik[defaultDil].baslik }} <br />
                      ( {{ arac.kapasite.min }} -
                      {{ arac.kapasite.max }} Kapasite )
                    </th>
                    <th class="text-right">
                      <b-button-group>
                        <b-button
                          squared
                          size="md"
                          :variant="tarife.statu ? 'info' : 'danger'"
                          v-b-tooltip.hover.top="
                            tarife.statu
                              ? 'Tarifeyi Pasif Et'
                              : 'Tarifeyi Aktif Et'
                          "
                          @click="tarife.statu = !tarife.statu"
                        >
                          <i
                            :class="`fad fa-${
                              tarife.statu ? 'eye' : 'eye-slash'
                            }`"
                          />
                        </b-button>
                        <b-button
                          squared
                          size="md"
                          variant="danger"
                          v-b-tooltip.hover.top="'Tarifeyi Kaldır'"
                          @click="handlerRemoveTarife(tarife.k_no)"
                        >
                          <i class="fad fa-trash" />
                        </b-button>
                      </b-button-group>
                    </th>
                  </tr>
                </thead>
                <draggable
                  v-model="form.bolgeler"
                  tag="tbody"
                  handle=".draggable-task-handle"
                >
                  <TarifeItem
                    v-for="(bolge, index) in form.bolgeler"
                    :key="index"
                    :index="index"
                    :item="bolge"
                    :tarife="tarife"
                    @removeItem="handlerRemoveItem($event)"
                  />
                </draggable>
              </table>
              <b-button
                type="submit"
                squared
                size="lg"
                variant="success"
                class="float-right"
              >
                <i class="fad fa-save" /> KAYDET
              </b-button>
            </b-form>
          </template>
        </div>
      </template>
    </template>
  </BTableCard>
</template>
<script>
import store from "@/store";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import { useRouter } from "@/libs/utils";
import { useToast } from "vue-toastification/composition";
import BTableCard from "@/components/cards/BTableCard.vue";
import TarifeItem from "./component/TarifeItem.vue";
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  onUpdated,
} from "@vue/composition-api";
import draggable from "vuedraggable";
export default defineComponent({
  components: {
    draggable,
    BTableCard,
    TarifeItem,
    vSelect,
  },
  setup() {
    const expo = {};
    const { route, router } = useRouter();
    const toast = useToast();
    expo.show = ref(false);
    expo.filtered = ref(false);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.total = ref(0);
    expo.pageTitle = ref("Transfer Tarife Listesi");
    expo.tarife = ref(null);
    expo.tarifeBolgeleri = computed(
      () => store.getters.getTransferTarifeBolgeleri
    );
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.araclar = computed(() => store.getters.getTransferAraclar);
    expo.old_form = ref({});
    expo.form = ref({});

    const handlerFetchAllData = async () => {
      expo.show.value = true;
      const statu = { statu: true };
      await store.dispatch("bolgeListele", statu);
      await store.dispatch("transferTarifeListele", statu);
      await store.dispatch("transferTarifeBolgeListele", statu);
      await store.dispatch("transferAracListele", statu);
      await store.dispatch("kurAyarlariGetir");

      expo.tarife.value = expo.tarifeBolgeleri.value[0];
      handlerTarifeCreated(expo.tarife.value);
      expo.show.value = false;
    };

    handlerFetchAllData();

    const handlerTarifeCreated = (event) => {
      const tarife = event;
      const tarifeBolgeleri = tarife.aktif_bolgeler;

      let newForm = {};

      const mevcut_tarife = store.getters.getTransferTarifeleri.tarifeler.find(
        (x) => x.k_no == tarife.k_no
      );

      if (mevcut_tarife) {
        newForm = { ...mevcut_tarife };
        tarifeBolgeleri.forEach((el, index) => {
          const varmi = mevcut_tarife.bolgeler.some((x) => x.k_no == el.k_no);
          if (!varmi) {
            newForm.bolgeler.push({
              k_no: el.k_no,
              araclar: [],
              statu: false,
            });

            expo.araclar.value.forEach((arac) => {
              newForm.bolgeler[index].araclar.push({
                k_no: arac.k_no,
                fiyat: 0,
              });
            });
          }

          const arac_tarife = newForm.bolgeler[index].araclar;
          newForm.bolgeler[index].araclar = [];

          expo.araclar.value.forEach((arac) => {
            const varmi = arac_tarife.some((x) => x.k_no == arac.k_no);

            var eslesenBolge = arac_tarife.find(
              (bolge) => bolge.k_no === arac.k_no
            );

            if (arac.k_no == eslesenBolge.k_no) {
              newForm.bolgeler[index].araclar.push(eslesenBolge);
            }

            if (!varmi) {
              newForm.bolgeler[index].araclar.push({
                k_no: arac.k_no,
                fiyat: 0,
              });
            }
          });
        });

        expo.form.value = newForm;
        expo.form.value.kur_turu = expo.kurTurleri.value.varsayilan;
      } else {
        newForm.k_no = tarife.k_no;
        expo.form.value.kur_turu = expo.kurTurleri.value.varsayilan;
        newForm.bolgeler = [];
        newForm.statu = true;

        tarifeBolgeleri.forEach((el, index) => {
          newForm.bolgeler.push({
            k_no: el.k_no,
            araclar: [],
            statu: false,
          });

          expo.araclar.value.forEach((arac) => {
            newForm.bolgeler[index].araclar.push({
              k_no: arac.k_no,
              fiyat: 0,
            });
          });
        });

        expo.form.value = newForm;
      }
    };

    expo.handlerTarifeBolgeChange = (event) => {
      handlerTarifeCreated(event);
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      expo.form.value.kur_turu = expo.kurTurleri.value.varsayilan;

      store.dispatch("transferTarifeEkle", expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success("Veriler Kaydedildi", { position: "bottom-left" });
          expo.show.value = false;
        }
      });
    };

    expo.handlerRemoveTarife = (k_no) => {
      Swal.fire({
        title: "Uyarı",
        text: "Kayit Silinecektir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          expo.show.value = true;
          store.dispatch("transferTarifeSil", k_no).then((res) => {
            if (res.data.success === true) {
              toast.error("Silme Başarılı", { position: "bottom-left" });
              expo.tarife.value = expo.tarifeBolgeleri.value[0];
              handlerTarifeCreated(expo.tarife.value);
              expo.show.value = false;
            }
          });
        }
      });
    };

    expo.handlerRemoveItem = (index) => {
      Swal.fire({
        title: "Uyarı",
        text: "Kayit Silinecektir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          expo.form.value.bolgeler.splice(index, 1);
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss">
table thead th,
tbody td {
  height: 50px;
  vertical-align: middle;
  font-weight: 600;
}
td {
  vertical-align: middle;
}
// table.vgt-table {
//   font-size: 14px;
//   thead {
//     background-color: rgba(#000, 0.07);
// table {
//   position: relative;
//   width: 100%;
//   white-space: nowrap;

//   tr > th.table-cars {
//     max-width: 2000px;
//   }

//   tr > th,
//   td {
//     position: sticky;
//     top: 0;
//   }

//   tr > th:nth-child(1),
//   th:nth-child(2),
//   th:nth-child(3) {
//     position: sticky;
//     left: 0;
//     z-index: 1;
//     background-color: #d1d1d1; /* Sabit sütunların arkaplan rengi */
//   }

//   tr > td:nth-child(1),
//   td:nth-child(2),
//   td:nth-child(3) {
//     position: sticky;
//     left: 0;
//     z-index: 1;
//     background-color: #fff; /* Sabit sütunların arkaplan rengi */
//   }

//   tr > td:nth-child(3) {
//     border-right: 2px solid dotted !important;
//   }
// }
</style>
