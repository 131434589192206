<template>
  <div class="p-2">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'tarih'">
          <router-link
            :to="{
              name: 'sayfa-guncelle',
              params: { k_no: props.row.k_no },
            }"
          >
            {{ props.row.save_date | momentFull }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'baslik'">
          <router-link
            :to="{
              name: 'sayfa-guncelle',
              params: { k_no: props.row.k_no },
            }"
          >
            {{ props.row.icerik[defaultDil].baslik }}
          </router-link>
        </span>
        <span class="d-flex align-items-center" v-else-if="props.column.field === 'dil' && diller.length > 1">
          <b-badge
            :variant="langColor(props.row.icerik[dil.lang].baslik)"
            class="rounded-0 mr-1"
            v-for="(dil, i) in diller"
            :key="i"
          >
            {{ dil.lang }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'kategori'">
          <b-badge variant="primary" class="rounded-0" v-if="props.row.kategori != null">
            {{ props.row.kategori[defaultDil].baslik }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'save_user'">
          <b-badge variant="success" class="rounded-0" v-if="props.row.save_user != ''">
            {{ props.row.save_user | str_limit(10) }}
          </b-badge>
          <b-badge variant="warning" class="rounded-0" v-if="props.row.edit_user != ''">
            {{ props.row.edit_user | str_limit(10) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.statu"
            switch
            @change="handlerStatu(props.row.k_no, $event)"
            size="lg"
          />
        </span>
        <span v-else-if="props.column.field === 'anasayfa'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.anasayfa"
            switch
            @change="handlerAnasayfa(props.row.k_no, $event)"
            size="lg"
          />
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              class="rounded-0"
              variant="warning"
              :to="{
                name: 'sayfa-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" />
            </b-button>
            <b-button variant="danger" class="rounded-0" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center my-3">
            <v-select
              v-model="pageLength"
              :options="perPageOptions"
              :clearable="false"
              style="width: 200px"
              class="invoice-filter-select"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="my-3"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <i class="fad fa-chevron-left" />
              </template>
              <template #next-text>
                <i class="fad fa-chevron-right" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import BTableCard from '@/components/cards/BTableCard.vue';
import { defineComponent, ref, computed } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    VueGoodTable,
    BTableCard,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.pageLength = ref(20);
    expo.perPageOptions = ref([10, 20, 50, 100]);
    expo.columns = ref([
      {
        label: 'Tarih',
        field: 'tarih',
        width: '14%',
      },
      {
        label: 'Başlık',
        field: 'baslik',
      },
      {
        label: 'Diller',
        field: 'dil',
        width: '8%',
        thClass: 'text-right',
        thClass: 'text-right',
        hidden: expo.diller.value.length < 2 && true,
      },
      {
        label: 'Kategori',
        field: 'kategori',
        width: '12%',
        tdClass: 'text-right',
      },
      {
        label: 'Ekl. / Günc.',
        field: 'save_user',
        width: '13%',
        tdClass: 'text-right',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '5%',
        tdClass: 'text-right',
      },
      {
        label: 'Anasayfa',
        field: 'anasayfa',
        width: '5%',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      await store.dispatch('sayfaListele');
      expo.rows.value = store.getters.getSayfalar;
      context.emit('show', false);
      context.emit('total', expo.rows.value.length);
    };
    handlerFetchAllData();

    expo.handlerStatu = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('magaza/sayfa-statu-guncelle', { k_no, statu: event }).then((res) => {
          if (res.data.success === true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          }
        });
      }
    };
    expo.handlerAnasayfa = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('magaza/sayfa-anasayfa-guncelle', { k_no, anasayfa: event }).then((res) => {
          if (res.data.success === true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          }
        });
      }
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('sayfaSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            } else {
              toast.warning(res.data.message, { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.langColor = (value) => {
      return value != '' ? 'success' : 'danger';
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
